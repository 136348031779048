export const faqSME = [
  {
    question: 'What is the "Power to switch" campaign?',
    solution:
      "Fortunately, in the UK, there has never been a better time to switch small business energy suppliers and take control of your own destiny. No longer should companies feel trapped by energy suppliers, or feel that the whole industry is a cartel that’s in it together, just waiting to rip people off. This point was emphasised recently when the government introduced its ‘Power to Switch’ campaign, which was designed to promote the possibility of switching energy suppliers among UK businesses. This helped to encourage them to take a good look at the various alternative energy providers on the market, in order to reduce the financial burden of paying for energy.",
  },
  {
    question: "What is the biggest cost for a UK business?",
    solution:
      "All businesses have operating costs and – whether it’s for stock or materials, premises renting, or anything else your business needs to keep running – it all adds up. One of the biggest costs for UK businesses can be energy suppliers. Whether you are paying via a monthly tariff or are on a yearly contract, paying over the odds for your commercial energy can be a significant drain on your company’s resources.",
  },
];

export const faqLarge = [
  {
    question: "What is corporate renewable energy?",
    solution:
      "Greener energy use is essential in the current business landscape. Most companies, no matter their size, are looking for ways to maximise their use of renewable energy. With global initiatives as well as national ones to be aware of, the end goal is always going to 100% renewable energy. However, a more bespoke approach is required. This is because all business models are different and will require a range of approaches that factor in the context of their energy use, the resources they have available, and their planned energy use throughout their growth strategy. As a result, for companies that are asking the question of “what is corporate renewable energy and how can we adopt it?”, the solutions are not always straightforward.",
  },
  {
    question: "What are MOP agreements?",
    solution:
      "A Meter Operator agreement is a legal requirement for all half-hourly meters. The agreement covers the maintenance and rental of the meter itself and the technology used to communicate meter readings to large business electricity suppliers. This element may be included within your business electricity bill as a separate charge, or you may be charged annually. If you need a MOP agreement organising, then your Direct Utilities Support account manager will be able to look into this for you. We have partnerships with accredited meter suppliers that can quote for your half-hourly meter.",
  },
  {
    question: "What's a kVA charge?",
    solution:
      "kVA, or “Kilo Vault Amperes”, is used to understand the amount of power that an electrical meter can feed to a building. 1 kVA equals 1,000 Watts of power. All companies with half-hourly meters have a kVA allowance, this can also be referred to as a capacity charge. This is worked out at twice the half-hour electricity usage for businesses.",
  },
  
];
