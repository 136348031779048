import React, { useState, useEffect } from "react";
import { BsTelephoneFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrFormClose } from "react-icons/gr";
import { Link, useLocation } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logo from "../images/logo.png";

const Navigation = () => {
  const [ToggleMenu, setToggleMenu] = useState(false);
  const location = useLocation();
  const [Dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const path = location.pathname;

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (path === "/") {
      document.getElementsByClassName("nav-home")[0]?.classList.add("active");
      document
        .getElementsByClassName("nav-services")[0]
        ?.classList.remove("active");
      document
        .getElementsByClassName("nav-contact")[0]
        ?.classList.remove("active");
      document
        .getElementsByClassName("nav-sectors")[0]
        ?.classList.remove("active");
      document
        .getElementsByClassName("nav-about")[0]
        ?.classList.remove("active");
    } else if (
      path === "/services/business-gas" ||
      path === "/services/business-electricity" ||
      path === "/services/business-electricity" ||
      path === "/services/business-water"
    ) {
      document
        .getElementsByClassName("nav-home")[0]
        ?.classList.remove("active");
      document
        .getElementsByClassName("nav-services")[0]
        ?.classList.add("active");
      document
        .getElementsByClassName("nav-contact")[0]
        ?.classList.remove("active");
      document
        .getElementsByClassName("nav-sectors")[0]
        ?.classList.remove("active");
      document
        .getElementsByClassName("nav-about")[0]
        ?.classList.remove("active");
    } else if (path.startsWith("/sectors")) {
      document
        .getElementsByClassName("nav-home")[0]
        ?.classList.remove("active");
      document
        .getElementsByClassName("nav-services")[0]
        ?.classList.remove("active");
      document
        .getElementsByClassName("nav-sectors")[0]
        ?.classList.add("active");
      document
        .getElementsByClassName("nav-contact")[0]
        ?.classList.remove("active");
      document
        .getElementsByClassName("nav-about")[0]
        ?.classList.remove("active");
    } else if (path === "/contact") {
      document
        .getElementsByClassName("nav-home")[0]
        ?.classList.remove("active");
      document
        .getElementsByClassName("nav-services")[0]
        ?.classList.remove("active");
      document
        .getElementsByClassName("nav-sectors")[0]
        ?.classList.remove("active");
      document
        .getElementsByClassName("nav-contact")[0]
        ?.classList.add("active");
      document
        .getElementsByClassName("nav-about")[0]
        ?.classList.remove("active");
    } else if (path === "/about") {
      document
        .getElementsByClassName("nav-home")[0]
        ?.classList.remove("active");
      document
        .getElementsByClassName("nav-sectors")[0]
        ?.classList.remove("active");
      document
        .getElementsByClassName("nav-services")[0]
        ?.classList.remove("active");
      document
        .getElementsByClassName("nav-contact")[0]
        ?.classList.remove("active");
      document.getElementsByClassName("nav-about")[0]?.classList.add("active");
    }
  }, [path, Dimensions.width]);

  const [CurrentHover, setCurrentHover] = useState("");

  useEffect(() => {
    if (ToggleMenu === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [ToggleMenu]);

  return (
    <div className="navigation">
      <div className="logo-menu" onMouseLeave={() => setCurrentHover("")}>
        <div className="Logo">
          <img src={logo} alt="Logo" />
        </div>
        {window.innerWidth < 820 ? (
          <div className="flex flex-row">
            <div
              style={{ color: "#00B3E8" }}
              className="rounded-2xl bg-white w-8 h-8 flex flex-row justify-around items-center mr-5 border-2 shadow-md cursor-pointer"
            >
              <a href="tel:0208 471 7796">
                <BsTelephoneFill />
              </a>
            </div>
            <div
              className="hamburger"
              onClick={() => {
                setToggleMenu(!ToggleMenu);
              }}
            >
              {!ToggleMenu ? (
                <GiHamburgerMenu size={25} />
              ) : (
                <GrFormClose size={25} />
              )}
            </div>
          </div>
        ) : (
          <div className="Menu">
            <div className="flex flex-row font-semibold w-full justify-between text-center self-center">
              <Link
                className="link nav-home"
                onMouseEnter={() => setCurrentHover("")}
                to="/"
              >
                Home
              </Link>
              <Link
                className="link nav-services"
                onMouseEnter={() => setCurrentHover("services")}
                to="#"
              >
                Services
              </Link>
              <Link
                className="link nav-sectors"
                onMouseEnter={() => setCurrentHover("sectors")}
                to=""
              >
                Sectors
              </Link>
              <Link
                className="link nav-about"
                onMouseEnter={() => setCurrentHover("")}
                to="/about"
              >
                About Us
              </Link>
              <Link
                className="link nav-contact"
                onMouseEnter={() => setCurrentHover("")}
                to="/contact"
              >
                Contact
              </Link>
            </div>
            <div
              style={{
                borderRadius: 10,
                height: "45px",
                minWidth: "170px",
                paddingTop: "10px",
              }}
              className="flex flex-row justify-between text-white px-3 font-semibold ml-10 cursor-pointer whitespace-nowrap bn632-hover bn26"
            >
              <a
                href="tel:0208 471 7796"
                style={{ paddingTop: 5 }}
                className="px-2"
              >
                <BsTelephoneFill />
              </a>
              <a href="tel:0208 471 7796">0208 471 7796</a>
            </div>
          </div>
        )}
      </div>
      {CurrentHover === "services" && (
        <div
          className="services-dropdown w-4/5"
          onMouseEnter={() => setCurrentHover("services")}
          onMouseLeave={() => setCurrentHover("")}
        >
          <div className="flex flex-col">
            <h1 className="text-gray-100 font-medium tracking-wide cursor-pointer text-xl pb-3">
              Services
            </h1>
            <p className="text-gray-100 tracking-wide">
              Choose from the variety of utility services <br />
              for your business and save upto 40% in costs <br /> with Direct
              Utilities Support.
            </p>
          </div>
          <div className="border-l-2 border-gray-100 h-5/6"></div>
          <div className="flex flex-col text-gray-100 justify-between h-6/6">
            <Link className="hover:underline" to="/services/business-gas">
              Business Gas
            </Link>
            <Link
              className="hover:underline"
              to="/services/business-electricity"
            >
              Business Electricity
            </Link>
            <Link className="hover:underline" to="/services/business-telecom">
              Business Telecom
            </Link>
            <Link className="hover:underline" to="/services/business-water">
              Business Water
            </Link>
          </div>
        </div>
      )}
      {CurrentHover === "sectors" && (
        <div
          className="services-dropdown w-4/5"
          onMouseEnter={() => setCurrentHover("sectors")}
          onMouseLeave={() => setCurrentHover("")}
        >
          <div className="flex flex-col">
            <h1 className="text-gray-100 font-medium tracking-wide cursor-pointer text-xl pb-3">
              Sectors
            </h1>
            <p className="text-gray-100 tracking-wide">
              Services tailored to suit your business <br /> requirements, no
              matter if you are a small, <br /> medium or large business we got
              you covered.
            </p>
          </div>
          <div className="border-l-2 border-gray-100 h-5/6"></div>
          <div className="flex flex-col text-gray-100 justify-between self-center h-2/5">
            <Link className="hover:underline" to="/sectors/sme-businesses">
              Small Medium Enterprises
            </Link>
            <Link className="hover:underline" to="/sectors/large-enterprise">
              Large Businesses
            </Link>
          </div>
        </div>
      )}
      {ToggleMenu ? (
        <div
          style={{
            top: 100,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#EEF5FC",
            zIndex: -9,
            overflow: "auto",
          }}
          className="fixed flex flex-col pt-5 cursor-pointer"
        >
          <div>
            <Link
              className="menu-link"
              onClick={() => setToggleMenu(false)}
              to="/"
            >
              Home
            </Link>
            <Accordion
              sx={{
                backgroundColor: "#EEF5FC !important",
                margin: "0 20px !important",
                boxShadow: "none !important",
                borderBottom: "1px solid #000",
                borderRadius: "0 !important",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ padding: "10px !important", height: "68px" }}
              >
                <p className="accordion-link">Services</p>
              </AccordionSummary>
              <AccordionDetails sx={{ margin: "0 0px !important", padding: 0 }}>
                <div
                  style={{ backgroundColor: "#000929" }}
                  className="flex flex-col w-full"
                >
                  <Link
                    className="drop-link text-gray-100"
                    onClick={() => setToggleMenu(false)}
                    to="/services/business-gas"
                  >
                    Business Gas
                  </Link>
                  <Link
                    className="drop-link text-gray-100"
                    onClick={() => setToggleMenu(false)}
                    to="/services/business-electricity"
                  >
                    Business Electricity
                  </Link>
                  <Link
                    className="drop-link text-gray-100"
                    onClick={() => setToggleMenu(false)}
                    to="/services/business-telecom"
                  >
                    Business Telecom
                  </Link>
                  <Link
                    className="drop-link text-gray-100"
                    onClick={() => setToggleMenu(false)}
                    to="/services/business-water"
                  >
                    Business Water
                  </Link>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: "#EEF5FC !important",
                margin: "0 20px !important",
                boxShadow: "none !important",
                borderBottom: "1px solid #000",
                borderRadius: "0 !important",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ padding: "10px !important", height: "68px" }}
              >
                <p className="accordion-link">Sectors</p>
              </AccordionSummary>
              <AccordionDetails sx={{ margin: "0 0px !important", padding: 0 }}>
                <div
                  style={{ backgroundColor: "#000929" }}
                  className="flex flex-col w-full"
                >
                  <Link
                    className="drop-link text-gray-100"
                    onClick={() => setToggleMenu(false)}
                    to="/sectors/sme-businesses"
                  >
                    Small Medium Enterprises
                  </Link>
                  <Link
                    className="drop-link text-gray-100"
                    onClick={() => setToggleMenu(false)}
                    to="/sectors/large-enterprise"
                  >
                    Large Businesses
                  </Link>
                </div>
              </AccordionDetails>
            </Accordion>
            <Link
              className="menu-link"
              onClick={() => setToggleMenu(false)}
              to="/about"
            >
              About Us
            </Link>
            <Link
              className="menu-link"
              onClick={() => setToggleMenu(false)}
              to="/contact"
            >
              Contact
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Navigation;
