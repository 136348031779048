export const faq = [
  {
    question: "How do I change the energy provider for my business?",
    solution:
      "If you are on a fixed rate tariff, you will normally be locked into that contract for an agreed length of time. This could be up to 5 years. When your contract is due to end, you can start looking for a new deal 6 months before you need to switch. If you’re unsure what your contract length is, you can check with your energy provider. If you are on a variable rate tariff, you can normally switch to a different tariff or provider once you have given your current provider 30 days notice.",
  },
  {
    question: "Why Should I Compare Energy Prices?",
    solution:
      "Comparing energy prices regularly is the only way to ensure you’re on the best deal. By comparing prices with a gas and electricity comparison services like ours, you can quickly and easily see where you could be saving money.",
  },
  {
    question: "How long does it take to switch energy supplier?",
    solution:
      "The switching process will depend on when your contract date with your current provider ends. Here at Direct Utilities Support, we take care of all the details, including negotiations between suppliers. We’ll keep you updated throughout the processes.",
  },
  {
    question: "Who is the best energy provider?",
    solution:
      "The energy market was once dominated by what’s commonly referred to as the 'big six' energy providers: British Gas, npower, Scottish Power, EDF, E-On and SSE. However, with the rise in independent providers, consumers now have more options to choose from. We at Direct Utilities Support ensure that you are well aware of your options and find the best deal.",
  },
  {
    question: "Which supplier provides the cheapest energy?",
    solution:
      "The rate of energy depends on various factors such as your location and your current rates for gas and electricity. Get in touch with us to find the best deal for you.",
  },
  {
    question: "How do I arrange a meeting with Direct Utilities Support?",
    solution:
      "To arrange a meeting, please contact us on +44 0208 471 7796 and one of our dedicated Business Advisers will be happy to help, or if you prefer please complete our online contact form and one of our Business Advisers will respond within 48 hours.",
  },
  {
    question:
      "why should I switch business utility with Direct Utilities Support?",
    solution:
      "At Direct Utilities Support, we want to do the best for your business, whether that means switching you to a better deal on business essentials, finding you the best possible finance solution, or offering information to help you run your business more efficiently. We’re completely independent and we always seek the best outcomes for our customers. If you’ve any questions about any area of our service, you can write to us your query using our contact page.",
  },
];
