import React, { useEffect } from "react";
import Aboutus from "../../images/large.jpg";
import Aboutus2 from "../../images/large2.png";
import "../Home/Home.css";
import bg from "../../images/companies/British-gas-2.png";
import cng from "../../images/companies/CNG.jpg";
import cgp from "../../images/companies/Crown-gas-power.jpg";
import eon from "../../images/companies/e.on-1.jpg";
import GazProm from "../../images/companies/Gazprom-1.jpg";
import Npower from "../../images/companies/Npower-1.jpg";
import opus from "../../images/companies/Opus-energy.jpg";
import scotish from "../../images/companies/scotishpower.jpg";
import SSE from "../../images/companies/Sse.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../../util/windows";

const About = () => {
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: width > 1000 ? 9 : width / 100,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 0,
  };

  return (
    <div className="overflow-x-hidden">
      <div className="h-12 md:h-20"></div>

      <div className="px-10 pt-12 pb-10 bg-gradient-to-r from-cyan-500 to-blue-500">
        <p className="text-3xl md:text-4xl pb-3 text-white font-bold">
          Direct Utilities Support
        </p>
        <p className="text-xl text-white font-light">
          We are a leading energy broker in UK
        </p>
      </div>
      <div className="px-10 pt-14 gap-y-10 gap-x-20 flex flex-row flex-wrap-reverse md:flex-nowrap justify-center">
        <div className="w-full xl:w-2/6 lg:w-3/6 md:w-3/6 self-center">
          <p className="mb-5">
            <b>
              Direct Utilities Support was established in the year 2009 with a
              mission to help businesses find suitable supplier for their energy
              needs and enable them to save big on costs.
            </b>
          </p>
          <p className="mb-5">
            We are a leading energy broker in the UK and have been providing our
            clients with the best energy suppliers since then. Over the years we
            have not only gained trust from our clients but also set an standard
            for others to follow.
          </p>
          <p>
            Since our establishment we have founded a team of experts who are
            committed to deliver 100% satisfaction to our clients.
          </p>
        </div>
        <div className="xl:w-2/6 lg:w-2/6 md:w-3/6 self-center">
          <img src={Aboutus} />
        </div>
      </div>

      <div className="px-10 pt-14 pb-14 gap-y-10 gap-x-20 flex flex-row flex-wrap md:flex-nowrap justify-center">
        <div className="xl:w-2/6 lg:w-2/6 md:w-3/6 self-center">
          <img src={Aboutus2} />
        </div>
        <div className="w-full xl:w-2/6 lg:w-3/6 md:w-3/6 self-center">
          <p className="mb-5">
            <b>
              We’ve helped people like you control and understand their energy
              costs for over a decade. We are a highly experienced business
              energy brokerage offering free consultancy and quotation services
              for the UK business market.
            </b>
          </p>
          <p className="mb-5">
            Direct utilities Support has built one of the largest, dependable
            networks in the utilities sector. We provide expert and disciplined
            advice in procurement, energy services, and technology.We have
            developed the know how to provide services across UK , providing
            energy renewals and switching services to tens of thousands of
            customers who choose to work with us.
          </p>
          <p>
            Since our establishment we have founded a team of experts who are
            committed to deliver 100% satisfaction to our clients.
          </p>
        </div>
      </div>

      <div className="px-10 md:px-20 py-20 bg-white">
        <h1
          style={{ color: "#00B3E8" }}
          className="text-2xl font-semibold pb-3"
        >
          Real peace of mind
        </h1>
        <h2 style={{ color: "#00B3E8" }} className="text-xl font-medium pb-3">
          Rock-solid products and services that won’t let you down
        </h2>
        <p className="text-md font-light pb-3">
          Good enough is not enough. Our energy tariffs will never have any
          hidden cost.
        </p>
        <p className="text-md font-light pb-3">
          You can always get it touch with our support team.
        </p>
        <p className="text-md font-light pb-3">
          We guide you through the entire process to ensure you make educated
          decision.
        </p>
        <p className="text-md font-light pb-3">
        And we promise our advice will always be straight forward and accurate.
        </p>
      </div>

      <div className="px-10 md:px-20 py-20 bg-gray-50">
        <h1
          style={{ color: "#00B3E8" }}
          className="text-2xl font-semibold pb-3"
        >
          Real care
        </h1>
        <h2 style={{ color: "#00B3E8" }} className="text-xl font-medium pb-3">
          Award-winning customer service that always looks out for you
        </h2>
        <p className="text-md font-light pb-3">
          Our people are the kind of people you would love to have as
          neighbours. <br />
          We treat you and your home with warmth, compassion and care.
        </p>
        <p className="text-md font-light pb-3">
          We care about our customers and we ensure that you get that care from
          the begining till end.
        </p>
      </div>
      {/* Begin ---- Utility Companies train */}

      <div className="bg-white py-10 shadow-2xl">
        <p
          style={{ color: "#01114C" }}
          className="mb-10 text-3xl text-center font-bold"
        >
          Our Suppliers
        </p>
        <Slider {...settings} className="UtilityGallery">
          <img src={bg} alt="British Gas" />
          <img src={cng} alt="CNG" />
          <img src={cgp} alt="Crown Gas Power" />
          <img src={eon} alt="Eon" />
          <img src={GazProm} alt="GazProm" />
          <img src={Npower} alt="Npower" />
          <img src={opus} alt="Opus" />
          <img src={scotish} alt="Scotish" />
          <img src={SSE} alt="SSE" />
          <img src={bg} alt="British Gas" />
          <img src={cng} alt="CNG" />
          <img src={cgp} alt="Crown Gas Power" />
          <img src={eon} alt="Eon" />
          <img src={GazProm} alt="GazProm" />
          <img src={Npower} alt="Npower" />
          <img src={opus} alt="Opus" />
          <img src={scotish} alt="Scotish" />
          <img src={SSE} alt="SSE" />
        </Slider>
      </div>

      {/* End ---- Utility Companies train */}

      <div
        style={{
          background:
            "linear-gradient( 41deg, rgba(2, 0, 36, 1) 0%, rgba(240, 245, 250, 1) 0%, rgba(235, 244, 252, 1) 100%)",
        }}
        className="py-10 px-10"
      >
        <h1 className="text-2xl self-center text-center font-bold">
          Services We Provide
        </h1>
        <div className="flex flex-row flex-wrap gap-x-10 gap-y-28 justify-evenly pt-10 mb-20">
          <div style={{ width: 300, height: 250 }} className="shadow-lg">
            <a href="/services/business-gas">
              <div className="rounded-t-lg bggas">
                <div
                  style={{ backgroundColor: "rgba(0,179,232,0.8)" }}
                  className="h-full rounded-t-lg"
                >
                  <h1 className="text-xl font-bold px-5 pt-5 pb-2 text-white">
                    Business Gas
                  </h1>
                  <p className="px-5 text-white font-lg">
                    Switching Utiilties can be a lot of hassle, but it doesn't
                    have to be that way. With Direct Utilities Support you can
                    forget the hassle and focus on your business.
                  </p>
                </div>
              </div>
              <div className="bg-white text-lg font-semibold text-center py-5 rounded-b-lg">
                <p>Business Gas</p>
              </div>
            </a>
          </div>

          <div style={{ width: 300, height: 250 }} className="shadow-lg">
            <a href="/services/business-electricity">
              <div className="rounded-t-lg bgelectricty">
                <div
                  style={{ backgroundColor: "rgba(0,179,232,0.8)" }}
                  className="h-full rounded-t-lg"
                >
                  <h1 className="text-xl font-bold px-5 pt-5 pb-2 text-white">
                    Business Electricity
                  </h1>
                  <p className="px-5 text-white font-lg">
                    Direct utilites Support has helped business save millions
                    over the years. We can help you switch to the best
                    electricity provider for your business.
                  </p>
                </div>
              </div>
              <div className="bg-white text-lg font-semibold text-center py-5 rounded-b-lg">
                <p>Business Electricity</p>
              </div>
            </a>
          </div>

          <div style={{ width: 300, height: 250 }} className="shadow-lg">
            <a href="/services/business-telecom">
              <div className="rounded-t-lg bgtelecom">
                <div
                  style={{ backgroundColor: "rgba(0,179,232,0.8)" }}
                  className="h-full rounded-t-lg"
                >
                  <h1 className="text-xl font-bold px-5 pt-5 pb-2 text-white">
                    Business Telecom
                  </h1>
                  <p className="px-5 text-white font-lg">
                    Help you business stay connected with the world. From mobile
                    phone and sim cards to landlines and VOIP, Direct Utilities
                    Support can guide you through the process.
                  </p>
                </div>
              </div>
              <div className="bg-white text-lg font-semibold text-center py-5 rounded-b-lg">
                <p>Business Telecom</p>
              </div>
            </a>
          </div>

          <div style={{ width: 300, height: 250 }} className="shadow-lg">
            <a href="/services/business-water">
              <div className="rounded-t-lg bgwater">
                <div
                  style={{ backgroundColor: "rgba(0,179,232,0.8)" }}
                  className="h-full rounded-t-lg"
                >
                  <h1 className="text-xl font-bold px-5 pt-5 pb-2 text-white">
                    Business Water
                  </h1>
                  <p className="px-5 text-white font-lg">
                    Over the years, Direct Utilities Support has helped
                    businesses do better by switching to the best water
                    provider. We take care of all the paperwork and hassle of
                    switching.
                  </p>
                </div>
              </div>
              <div className="bg-white text-lg font-semibold text-center py-5 rounded-b-lg">
                <p>Business Water</p>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="py-10 bg-white text-center">
        <h1 style={{ color: "#00B3E8" }} className="text-2xl font-bold">
          Call us at <a href="tel:08006345467">0208 471 7796</a>
        </h1>
        <p className="font-light text-lg">
          For any queries related to utilites switiching or renewal
        </p>
      </div>
    </div>
  );
};

export default About;
