import React, { useEffect } from "react";
import Particles from "react-tsparticles";
import Slider from "react-slick";
import useWindowDimensions from "../../util/windows";
import { useLocation } from "react-router-dom";
import RoundedCards from "../../components/RoundedCards";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Accordion } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { faq } from "../Home/faq";
import Paper from "@mui/material/Paper";
import Aboutus from "../../images/large.jpg";
import smeone from "../../images/smeone.jpg";
import smetwo from "../../images/smetwo.jpg";
// icons
import { BsTelephoneFill } from "react-icons/bs";
import { MdOutlineSavings } from "react-icons/md";
import { BiMoney } from "react-icons/bi";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IoMdBusiness } from "react-icons/io";

// images
import ellipse from "../../images/Ellipse.png";
import plus from "../../images/plus.png";
import bg from "../../images/companies/British-gas-2.png";
import cng from "../../images/companies/CNG.jpg";
import cgp from "../../images/companies/Crown-gas-power.jpg";
import eon from "../../images/companies/e.on-1.jpg";
import GazProm from "../../images/companies/Gazprom-1.jpg";
import Npower from "../../images/companies/Npower-1.jpg";
import opus from "../../images/companies/Opus-energy.jpg";
import scotish from "../../images/companies/scotishpower.jpg";
import SSE from "../../images/companies/Sse.jpg";
import bizGas from "../../images/businessGas.png";
import bizElectricity from "../../images/businessElectricity.png";
import bizWater from "../../images/water.png";
import bizTelecom from "../../images/telecom.png";
import smeBiz from "../../images/smeBiz.png";

// css
import "../Home/Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { faqSME } from "./faqSector";

const SME = () => {
  const { width } = useWindowDimensions();
  const location = useLocation();
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: width > 1000 ? 9 : width / 100,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 0,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];

  return (
    <>
      {/* Begin ---- Utility Companies train */}
      <div className="overflow-x-hidden">
        {/* space to take space for navigation below */}
        <div className="space"></div>
        <div className="banner">
          <div className="bannerInfo">
            <div className="flex flex-row px-2 pt-2 rounded-xl highlighted items-center">
              <span className="rounded-full redCircle h-6 flex flex-col justify-end">
                <img src={smeBiz} alt="SME" />
              </span>
              <p className="self-center ml-3 pt-2 text-gray-700 font-medium">
                Small &amp; Medium Businesses
              </p>
            </div>
            <div className="font-bold bigText">
              Utility Advice
              <br />
              From Experts
              <br />
              For SMEs
            </div>
            <div className="smallText text-gray-700">
              Best business support &amp; utilities, guaranteed.
              <br />
              At Direct Utility Support we make <br />
              business satisfaction our priority.
            </div>

            <div className="contact">
              <a href="tel:0208 471 7796">
                <button class="bn632-hover bn26 flex flex-row justify-center items-center">
                  <BsTelephoneFill /> &nbsp; 0208 471 7796
                </button>
              </a>
              {/* <p className="self-center">
                <BsTelephoneFill />
              </p>
              <p>0208 471 7796</p> */}
            </div>
          </div>
          <div className="bannerImage">
            <div className="SMEbusiness"></div>
          </div>
        </div>
        {/* End of the Banner */}

        {/* the blue band below */}
        {/* <div className="band"></div> */}
        <div className="utilityCompanies shadow-2xl">
          <p className="mb-2 text-gray-700 mx-5">
            We compare prices among the most trusted utility suppliers in UK.
          </p>
          <Slider {...settings} className="UtilityGallery">
            <img src={bg} alt="British Gas" />
            <img src={cng} alt="CNG" />
            <img src={cgp} alt="Crown Gas Power" />
            <img src={eon} alt="Eon" />
            <img src={GazProm} alt="GazProm" />
            <img src={Npower} alt="Npower" />
            <img src={opus} alt="Opus" />
            <img src={scotish} alt="Scotish" />
            <img src={SSE} alt="SSE" />
            <img src={bg} alt="British Gas" />
            <img src={cng} alt="CNG" />
            <img src={cgp} alt="Crown Gas Power" />
            <img src={eon} alt="Eon" />
            <img src={GazProm} alt="GazProm" />
            <img src={Npower} alt="Npower" />
            <img src={opus} alt="Opus" />
            <img src={scotish} alt="Scotish" />
            <img src={SSE} alt="SSE" />
          </Slider>
        </div>

        {/* End ---- Utility Companies train */}
      </div>
      <div className="Switching">
        <h1 className="text-white text-2xl font-light self-center text-center pt-10 pb-8">
          How can Direct Utilities Support help you
        </h1>
        <p className="ServicesTitle"></p>
        <div className="flex flex-row justify-evenly gap-x-10 gap-y-20 mt-12 mb-12 flex-wrap">
          <div className="flex flex-col items-center">
            <p
              style={{ fontSize: "3em" }}
              className="text-xl font-light flex flex-row pb-4"
            >
              <IoMdBusiness /> <span className="pl-4 mt-2">10K</span>
            </p>
            <p className="text-xl">Businesses Helped</p>
          </div>
          <div className="flex flex-col items-center">
            <p
              style={{ fontSize: "3em" }}
              className="text-xl font-light flex flex-row pb-4"
            >
              <MdOutlineSavings /> <span className="pl-4 mt-2">45%</span>
            </p>
            <p className="text-xl">Cost Savings On Bills</p>
          </div>
          <div className="flex flex-col items-center">
            <p
              style={{ fontSize: "3em" }}
              className="text-xl font-light flex flex-row pb-4"
            >
              <BiMoney /> <span className="pl-4 mt-2">£11m</span>
            </p>
            <p className="text-xl">Annualy Customer Spend</p>
          </div>
        </div>
      </div>
      <div className="flex flex-column bg-white lg:flex-row flex-wrap justify-evenly items-center gap-y-10 gap-x-8 p-10">
        <RoundedCards
          title="Business Gas"
          icon={bizGas}
          description="Switching Utiilties can be a lot of hassle, but it doesn't have to be that way. With Direct Utilities Support you can forget the hassle and focus on your business."
          link="/services/business-gas"
          color="#faae2b"
          textColor="#00473e"
        />
        <RoundedCards
          title="Business Electricity"
          icon={bizElectricity}
          description="Direct utilites Support has helped business save millions over the years. We can help you switch to the best electricity provider for your business."
          link="/services/business-electricity"
          color="#22B573"
          textColor="#00473e"
        />
        <RoundedCards
          title="Business Telecom"
          icon={bizTelecom}
          description="Help you business stay connected with the world. From mobile phone and sim cards to landlines and VOIP, Direct Utilities Support can guide you through the process."
          link="/services/business-telecom"
          color="#b8bad3"
          textColor="#00473e"
        />
        <RoundedCards
          title="Business Water"
          icon={bizWater}
          description="Over the years, Direct Utilities Support has helped businesses do better by switching to the best water provider. We take care of all the paperwork and hassle of switching."
          link="/services/business-water"
          color="#3FA9F5"
          textColor="#00473e"
        />
      </div>

      <div className="px-10 bg-white pt-14 pb-14 gap-y-10 gap-x-20 flex flex-row flex-wrap-reverse lg:flex-nowrap justify-center">
        <div className="w-full xl:w-3/6 lg:w-4/6 md:w-4/6 self-center">
          <p className="mb-5">
            <b>
              If you are the owner of a small or medium-sized enterprise, you’ll
              be very aware of the numerous day-to-day challenges it can
              present. To keep an SME business running efficiently, it is
              important that all SME business energy outgoings are analysed to
              see where savings can be made.
            </b>
          </p>
          <p className="mb-5">
            You should start by investigating your current spend on small
            business utilities. Need a hand? We are experienced in working with
            small to medium enterprise companies, helping them save on their SME
            business energy and water prices.
          </p>
          <p>
            To see how we can help you cut down on your small business
            electricity prices and utility rates, contact a member of our team
            today and we can help you find the best rates.
          </p>
        </div>
        <div className="xl:w-2/6 lg:w-2/6 md:w-4/6 self-center">
          <img src={smeone} />
        </div>
      </div>
      <div className="px-10 pt-14 pb-14 bg-white gap-y-10 gap-x-20 flex flex-row flex-wrap lg:flex-nowrap justify-center">
        <div className="xl:w-2/6 lg:w-2/6 md:w-4/6 self-center">
          <img src={smetwo} />
        </div>
        <div className="w-full xl:w-3/6 lg:w-4/6 md:w-4/6 self-center">
          <p className="mb-5">
            <b>
              Green SME business energy suppliers &mdash; With rising carbon
              emissions and the increasing problems caused by global warming,
              switching your SME business energy to green energy can make a huge
              impact.
            </b>
          </p>
          <p className="mb-5">
            The fuels used in green energy come from sources that won’t expire,
            unlike fossil fuels. Fossil fuels are short in supply, which could
            lead to higher prices as they become harder to obtain – which, in
            turn, can also lead to you paying higher small business electricity
            prices.
          </p>
          <p>
            Our experts can advise you on the best green small business energy
            suppliers and deals. We understand that SME business energy needs
            are different to other organisations, which is why we look for deals
            and suppliers that are catered to your specific business
            requirements.
          </p>
        </div>
      </div>
      {/* Begin FAQ */}
      <div className="FAQ">
        <h1 className="text-left text-2xl font-bold mb-6 px-4">
          SMEs Frequently Asked Question
        </h1>
        <p className="text-left text-sm w-4/4 lg:w-3/4 px-4">
          At Direct Utilities Support, we understand our customers. Our more
          than a decade of experience assisting our customers with switching
          utilities has helped us learn the common queries that goes in a
          customer's mind when they are switching utilities.
        </p>
        <div className="mt-10">
          {faqSME.map((item, index) => {
            return (
              <Accordion
                style={{
                  boxShadow: "none",
                  borderBottom: "1px solid #68707C",
                }}
                defaultExpanded
                key={index}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="font-semibold"
                >
                  <p>{item.question}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{item.solution}</p>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>

      <div className="py-10 bg-white text-center">
        <h1 style={{ color: "#00B3E8" }} className="text-2xl font-bold">
          Call us at <a href="tel:08006345467">0208 471 7796</a>
        </h1>
        <p className="font-light text-lg">
          For any queries related to utilites switiching or renewal
        </p>
      </div>
      {/* End Faq */}
      {/* Moving Particles in the banner */}
      <Particles
        id="tsparticles"
        options={{
          fpsLimit: 60,
          particles: {
            color: {
              value: "#000",
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 0.2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 40,
            },
            shape: {
              type: "images",
              images: [
                {
                  src: ellipse,
                  height: 20,
                  width: 23,
                },
                {
                  src: plus,
                  height: 20,
                  width: 23,
                },
              ],
            },
            size: {
              random: true,
              value: 10,
            },
          },
          detectRetina: true,
        }}
      />
    </>
  );
};

export default SME;
