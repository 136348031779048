export const faqElectricity = [
  {
    question: "What is a Meter Point Access Number (MPAN)?",
    solution:
      "MPAN is the abbreviation used for Meter Point Administration Number. The MPAN is the bottom line of 13 numbers included as part of the ‘Supply Number’. The first 2-digits are the ‘Distribution ID’, then an 8-digit ‘unique identifier’ with the final 3-digits making up what’s known as the ‘check digit’. The top line of the supply number includes a further 8-digits. The first 2-digits of this line of numbers are the meter ‘Profile type’, followed by a 3-digit Meter ‘Time-Switch code’ and a further 3-digit ‘Line Loss Factor’.",
  },
  {
    question: "What is a Change of Tenancy (CoT)?",
    solution:
      "A change of Tenancy (CoT) occurs when a business moves into or out of a premise.",
  },
  {
    question: "What is a Half Hourly meter?",
    solution:
      "A Half Hourly meter is an electricity meter that utilises Automatic Meter Reading technology (AMR) to send meter readings to the supplier every 30 minutes. The meter ‘Supply Number’ will have a ‘profile type’ code of 00. From April 1st 2017, all businesses with meter profile classes of 05, 06, 07 or 08 became part of an Office of Gas and Electricity Markets (OFGEM) backed revision known as P272, to re-designate meters to the 00 Half hourly profile class.",
  },
  {
    question: "What is a Letter of Authority (LoA)?",
    solution:
      "A letter of authority (LoA), also commonly referred to as a permissions letter, is a legal document that allows customers to authorise a third party to act on their behalf within agreed limits.",
  },
  {
    question:
      "Why do you need a permissions letter – Letter of Authority (LoA)?",
    solution:
      "Suppliers require us to hold a permissions letter or Letter of Authority to confirm current details about your supply and meter.",
  },
  {
    question: "How do I find out when my electricity contract ends? ",
    solution:
      "Your contract end date can be found on your bill, but if you’re struggling to find it then we can help. Our electricity brokers are experts at analysing bills, keeping you from paying inflated, out of contract rates. Thanks to our brokers, we can help you switch to the best deal for your company.",
  },
  {
    question: "What is a smart meter?",
    solution:
      "A smart mater automatically checks your energy consumption every month. Subsequently, your electricity bills will be more accurate, which can help save on your electricity usage. A lot of suppliers can offer smart meters for free as part of your deal.",
  },
];

export const faqTelecom = [
  {
    question: "Which broadband deals is best for me?",
    solution:
      "This depends on what you’re using the internet for. It isn’t always the cheaper the better. If your households use the internet for gaming or streaming, you will need faster speeds to manage the demand. For basic internet search, email and social media, a cheap broadband deal is perfect. For businesses, high-quality speeds may be more required. Super-fast connectivity often results in higher productivity. You can compare deals here.",
  },
  {
    question: "What is a sim only deal?",
    solution:
      "A sim-only covers the mobile service only, not the device. The service gives you a set monthly amount of call minutes, texts, and data. It is much cheaper than a traditional mobile contract as you will need to supply your own handset.",
  },
  {
    question: "What is VOIP?",
    solution:
      "Voice over Internet Protocol (VoIP) allows users to make call via the internet instead of traditional phone lines. Using a VoIP is much cheaper than using traditional lines.",
  },
  {
    question: "How do I set up my team for remote working?",
    solution:
      "In a world where team members may be working in different locations, communication is critical, and it is important that customer service does not suffer. Investing in a VoIP system is a great asset for remote working. VoIP allows businesses to ‘twin’ user handsets for simultaneous inbound calling. Mobile handsets can also be ‘twinned’. This means that a team member is always available.",
  },
  {
    question:
      "Why do you need a permissions letter – Letter of Authority (LoA)?",
    solution:
      "Suppliers require us to hold a permissions letter or Letter of Authority to confirm current details about your supply and meter.",
  },
  {
    question: "How do I find out when my electricity contract ends? ",
    solution:
      "Your contract end date can be found on your bill, but if you’re struggling to find it then we can help. Our electricity brokers are experts at analysing bills, keeping you from paying inflated, out of contract rates. Thanks to our brokers, we can help you switch to the best deal for your company.",
  },
  {
    question: "What is the PSTN Switch Off?",
    solution:
      "PSTN stands for the Public Switched Telephone Network. This network is set to be switched off by Openreach in December 2025. The PSTN switch off will affect both businesses and households as traditional phone lines and internet connections will no longer have a dial tone or connectivity. To stay connected, businesses will need to move to alternative services such as fibre broadband and VoIP (Voice over Internet Protocol).",
  },
];

export const faqWater = [
  {
    question: "When was water deregulated?",
    solution:
      "As of 1st April 2017, the non-domestic water market was deregulated. This means that like , all businesses within England can now choose which company supplies their water and waste water services. While the water wholesaler will be determined by the region, the supplier can be chosen by you. The changes came after the successful deregulation of water in Scotland in 2008.",
  },
  {
    question: "Which UK water suppliers can I choose from?",
    solution:
      "New companies have entered the market and many of these have a utilities background. Recently, more energy suppliers have added water to their list of services and others will be teaming up with regional water companies. Some regional water companies now operate on a national basis, and some have chosen to withdraw completely – leaving those with contracts having to change water supplier.",
  },
  {
    question: "How much are business water rates in the UK?",
    solution:
      "Business water bills vary in value depending on your commercial location, company size and usage. Our experienced water team can audit your bill and gather the relevant information to help find you the best commercial water quotes and prices for your business. If you have any questions, feel free to contact our team.",
  },
  {
    question: "What is a section 104 sewer?",
    solution:
      "A section 104 sewer agreement is a strict agreement between businesses and sewerage undertakers. For developments and businesses, the sewage and wastewater systems have to meet agreed standards. This is called a section 104 agreement.",
  },
  {
    question:
      "What is the difference between surface water drainage and highway drainage?",
    solution:
      "Surface water and highway drainage are separate items on your water bill. The highway drainage charge covers water that drains from public roads into the public sewer. Surface water drainage is the charge for water that drains from privately owned properties into the public sewers.",
  },
  {
    question: "How do I know if I have a soakaway?",
    solution:
      "Check rainwater pipes from your property to see if they connect to the public sewer. By checking if you have a septic tank as properties without access to the public sewer for foul water will often have soakaways installed for surface water drainage.",
  },
  {
    question: "How do I find my water meter number?",
    solution:
      "Your water meter will have a unique serial number known as your water meter number. The meter is, normally, found outside your property, in a small chamber under a metal or plastic cover. Sometimes it may be located next to the stop tap for your property.",
  },
];
