import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./components/Navigation";
import Home from "./pages/Home/Home";
import BusinessGas from "./pages/Service/BusinessGas";
import BusinessElectricity from "./pages/Service/BusinessElectricity";
import BusinessTelecom from "./pages/Service/BusinessTelecom";
import BusinessWater from "./pages/Service/BusinessWater";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/About";
import { AiFillMail, AiFillPhone } from "react-icons/ai";
import logoWhite from "./images/logoWhite.png";
import SME from "./pages/Sectors/SME";
import LargeEnterprises from "./pages/Sectors/LargeEnterprises";
import Policy from "./pages/Policy";
import Tc from "./pages/Tc";
import Complaints from "./pages/Complaints";
import { Link } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <div>
          {" "}
          <Navigation />
        </div>
        <div className="space"></div>
        <div className="flex flex-col">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services/business-gas" element={<BusinessGas />} />
            <Route
              path="/services/business-electricity"
              element={<BusinessElectricity />}
            />
            <Route
              path="/services/business-telecom"
              element={<BusinessTelecom />}
            />
            <Route
              path="/services/business-water"
              element={<BusinessWater />}
            />
            <Route path="/sectors/sme-businesses" element={<SME />} />
            <Route
              path="/sectors/large-enterprise"
              element={<LargeEnterprises />}
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy-policy" element={<Policy />} />
            <Route path="/terms-and-conditions" element={<Tc />} />
            <Route path="/complaints-procedure" element={<Complaints />} />
          </Routes>
        </div>
        {/* Begin Footer */}
        <div className="Footer">
          <>
            <div className="FootLeft">
              <img src={logoWhite} alt="DirectUtilitiesSupport" />
              <p className="h-24">
                Direct Utilities Support was established back in 2009 with the
                aim to provide the best service to our customers by providing
                great customer care and transperency in the process.
              </p>
            </div>

            <div className="Foot">
              <div className="FootBlocks">
                <h1>Contact us</h1>
                <div className="contactusP">
                  <AiFillPhone style={{ marginTop: "1", marginRight: 4 }} />
                  <a href="tel:0208 471 7796">0208 471 7796 </a>
                </div>
                <div className="contactusP">
                  <AiFillMail style={{ marginTop: "1", marginRight: 4 }} />
                  <a
                    className="cursor-pointer text-white"
                    href="mailto:info@directutilitiessupport.co.uk"
                  >
                    info@directutilitiessupport.co.uk
                  </a>
                </div>
              </div>
              <div className="FootBlocks">
                <h1>Services</h1>
                <Link to="/services/business-gas">Business Gas</Link>
                <Link to="/services/business-electricity">
                  Business Electricity
                </Link>
                <Link to="/services/business-telecom">Business Telecom</Link>
                <Link to="/services/business-water">Business Water</Link>
              </div>
            </div>
            <div className="Foot">
              <div className="FootBlocks">
                <h1>Company</h1>
                <Link to="/about">About us</Link>
                <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/complaints-procedure">Complaints Procedure</Link>
              </div>
              <div className="FootBlocks">
                <h1>Sectors</h1>
                <Link to="/sectors/sme-businesses">SMEs</Link>
                <Link to="/sectors/large-enterprise">Large businesses</Link>
              </div>
            </div>
          </>
          <p className="copyright">© Direct Utilities Support 2022</p>
        </div>
        {/* End Footer */}
      </div>
    </Router>
  );
}

export default App;
