import React, { useState, useRef, useEffect } from "react";
import "./Home.css";
import { BsTelephoneFill } from "react-icons/bs";
import Particles from "react-tsparticles";
import ellipse from "../../images/Ellipse.png";
import plus from "../../images/plus.png";
import bg from "../../images/companies/British-gas-2.png";
import cng from "../../images/companies/CNG.jpg";
import cgp from "../../images/companies/Crown-gas-power.jpg";
import eon from "../../images/companies/e.on-1.jpg";
import GazProm from "../../images/companies/Gazprom-1.jpg";
import Npower from "../../images/companies/Npower-1.jpg";
import opus from "../../images/companies/Opus-energy.jpg";
import scotish from "../../images/companies/scotishpower.jpg";
import SSE from "../../images/companies/Sse.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bizElectricty from "../../images/businessElectricity.png";
import bizGas from "../../images/businessGas.png";
import bizTele from "../../images/telecom.png";
import bizWater from "../../images/water.png";
import Process from "../../images/process.png";
import discovery from "../../images/discovery.png";
import plan from "../../images/plan.png";
import execute from "../../images/execute.png";
import deliver from "../../images/deliver.png";
import us from "../../images/us.png";
import { faq } from "./faq";
import { AiFillMail, AiFillPhone } from "react-icons/ai";
import { Accordion } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Slider from "react-slick";
import useWindowDimensions from "../../util/windows";
import ReactStarsRating from "react-awesome-stars-rating";
import { useLocation } from "react-router-dom";

const Home = () => {
  const { height, width } = useWindowDimensions();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: width > 1000 ? 9 : width / 100,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 0,
  };

  return (
    <>
      {/* Main wrapper tag */}
      <div className="Home">
        {/* space to take space for navigation below */}
        <div className="space"></div>
        {/* Start of the Banner */}
        <div className="banner">
          <div className="bannerInfo">
            <div className="flex flex-row bg-gray-200 px-3 py-2 rounded-xl highlighted">
              <span
                style={{ backgroundColor: "#0088E8" }}
                className="rounded-full redCircle"
              ></span>
              <p className="self-center ml-3 text-gray-700 font-medium">
                Unbiased Utility Switching Services
              </p>
            </div>
            <div className="font-bold bigText">
              Over 10,000
              <br />
              Businesses
              <br />
              Trusts Us
            </div>
            <div className="smallText text-gray-700">
              Direct Utilities Support helps small,
              <br />
              medium and large businesses save <br />
              40% in costs on their utility bills.
            </div>

            <div className="contact">
              <a href="tel:0208 471 7796">
                <button class="bn632-hover bn26 flex flex-row justify-center items-center">
                  <BsTelephoneFill /> &nbsp; 0208 471 7796
                </button>
              </a>
              {/* <p className="self-center">
                <BsTelephoneFill />
              </p>
              <p>0208 471 7796</p> */}
            </div>
          </div>
          <div className="bannerImage">
            <div className="circleImage"></div>
          </div>
        </div>
        {/* End of the Banner */}

        {/* the blue band below */}
        {/* <div className="band"></div> */}

        {/* Begin ---- Utility Companies train */}

        <div className="utilityCompanies">
          <h1 className="text-center text-2xl font-bold mb-6 mt-6">
            Companies We Work With
          </h1>
          <p className="mb-2 text-gray-700 mx-5">
            We compare prices among the most trusted utility suppliers in UK.
          </p>
          <Slider {...settings} className="UtilityGallery">
            <img src={bg} alt="British Gas" />
            <img src={cng} alt="CNG" />
            <img src={cgp} alt="Crown Gas Power" />
            <img src={eon} alt="Eon" />
            <img src={GazProm} alt="GazProm" />
            <img src={Npower} alt="Npower" />
            <img src={opus} alt="Opus" />
            <img src={scotish} alt="Scotish" />
            <img src={SSE} alt="SSE" />
            <img src={bg} alt="British Gas" />
            <img src={cng} alt="CNG" />
            <img src={cgp} alt="Crown Gas Power" />
            <img src={eon} alt="Eon" />
            <img src={GazProm} alt="GazProm" />
            <img src={Npower} alt="Npower" />
            <img src={opus} alt="Opus" />
            <img src={scotish} alt="Scotish" />
            <img src={SSE} alt="SSE" />
          </Slider>
        </div>

        {/* End ---- Utility Companies train */}

        {/* Begining fo the Services brief*/}
        <div className="Services">
          <h1 className="Headings">
            Services That Lead The Way To Better Business
          </h1>
          <p className="ServicesTitle">
            We at Direct Utilities Support understand the importance of energy
            for any business. Hence over the decade, we have gathered a team of
            experts to guide you in the process of finding the best supplier for
            your business needs.
          </p>
          <div className="ServicesBrief">
            <div className="briefs">
              <div className="CircleIcons">
                <img src={bizElectricty} alt="Gas" />
              </div>
              <div className="briefHeadings">
                <h1>Business Electricity</h1>
                <p className="w-64">
                  We take pride in our ability to provide the best deals to our
                  clients. Our experts has helped businesses save thousands on
                  electricity bills.
                </p>
              </div>
            </div>
            <div className="briefs">
              <div className="CircleIcons">
                <img src={bizGas} alt="Electricity" />
              </div>
              <div className="briefHeadings">
                <h1>Business Gas</h1>
                <p className="w-64">
                  We can help you find the best gas supplier for your business
                  needs. If you are thinking of cutting costs, you should think
                  of us.
                </p>
              </div>
            </div>
            <div className="briefs">
              <div className="CircleIcons">
                <img src={bizTele} alt="Telecom" />
              </div>
              <div className="briefHeadings">
                <h1>Business Telecom</h1>
                <p className="w-64">
                  Looking for the best rates, we can bring you up to speed.
                  Mobile and sim cards to landline and VoIP services to make
                  your life easier.
                </p>
              </div>
            </div>
            <div className="briefs">
              <div className="CircleIcons">
                <img src={bizWater} alt="Water" />
              </div>
              <div className="briefHeadings">
                <h1>Business Water</h1>
                <p className="w-64">
                  Find the best water supplier for your business needs and cut
                  down on your water costs. Great suppliers at best prices
                </p>
              </div>
            </div>
            <div className="briefs"></div>
          </div>
        </div>
        {/* End of the services brief */}

        {/* Begin - our process */}
        <div className="ourProcess bg-white">
          <div className="ProcessImg">
            <img src={Process} alt="Our Process" />
          </div>

          <div className="ProcessBriefs">
            <h1 className="text-2xl font-bold mb-6">Our Process</h1>
            <p className="text-gray-700">
              Finding the best utility supplier for your business needs couldn't
              get any better than us. Our decades of experience in the industry
              has helped us create a process to better understand your
              requirements and help you find the supplier that best suits your
              needs.
            </p>

            <div className="Process">
              <img src={discovery} alt="Discovery" />
              <div className="ProcessCards">
                <h1>Discovery</h1>
                <p>
                  get in touch with you to understand your business and its
                  energy consumptions. We then work with you to recognize your
                  needs and how you can use our services in best possible way.
                </p>{" "}
              </div>
            </div>
            <div className="Process">
              <img src={plan} alt="Plan" />
              <div className="ProcessCards">
                <h1>Plan</h1>
                <p>
                  Our next step is to guide you through the rates that are
                  available and find the deal that best suits your needs. Our
                  services are completely unbias and transparent because we
                  believe in building trust above all.
                </p>{" "}
              </div>
            </div>
            <div className="Process">
              <img src={execute} alt="Execute" />
              <div className="ProcessCards">
                <h1>Execute</h1>
                <p>
                  Once we make sure that we have explored all the available
                  options and found a plan that you have agreed upon, we take it
                  to the execution stage. We ensure that you remain hassle-free
                  during the switch.
                </p>{" "}
              </div>
            </div>
            <div className="Process">
              <img src={deliver} alt="Deliver" />
              <div className="ProcessCards">
                <h1>Deliver</h1>
                <p>
                  We will take care of the entire process that goes behind,
                  informing your old and new supplier about the switch and
                  throughout keeping you informed in every step guaranteeing
                  transperent service.
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
        {/* End ---- Our Process */}

        {/* Begin ---- Sectors */}
        <div className="Sectors">
          <div className="SectorsHeader">
            <h1 className="text-center text-2xl font-bold mb-6">
              Our Services Across Sectors
            </h1>
            <p className="text-gray-700">
              Direct Utilities Support understands that different types of
              businesses has different energy needs. We have a team of experts
              who acknowledge the scale of your business and tailor our services
              to your needs.
            </p>
          </div>
          <div className="card-container">
            <div className="SectorCard">
              <h1>Small Medium Enterprises</h1>
              <p>
                Small and medium enterprises functions a lot differently than a
                large business and we understand that. We help small business
                save big on their bills hence maximizing their profits.
              </p>
            </div>
            <div className="SectorCard">
              <h1>Large Business</h1>
              <p>
                Large business are big on their resources which often leads to
                greater energy consumptions and thus sky-rocketting their costs.
                We are here to change that by providing tailored services such
                that it just fits.
              </p>
            </div>
          </div>
        </div>
        {/* End ---- Sectors */}

        {/* Begin statement */}
        <div className="flex flex-row items-center p-10 h-auto gap-3 statement">
          <h1 className="font-bold text-2xl px-4">
            We help our customers find the best energy prices from UK’s top
            utility and energy suppliers.
          </h1>
          <p className="text-sm px-2">
            Our aim is to provide the best form of utility for our customers who
            have placed their indomitable faith in us. With our resources and
            our aim, we wish to make ourselves the best provider of utility
            services in the UK. Our customer service is extremely enriched for
            the consumers.
          </p>
        </div>
        {/* End statement */}

        {/* Begin Why us */}
        <div className="WhyUs">
          <img src={us} alt="why us" />
          <div className="WhyUsPara">
            <h1 className="text-2xl font-bold">
              Why Direct Utilities Support?
            </h1>
            <p className="py-5">
              The entire process of switching from one supplier to another can
              quickly become a hassle. From finding all the available options
              and comparing the prices to filling out innumerous forms to the
              execution of the switch, it can be a very time consuming process.
              <br />
              <br />
              That is where Direct Utilities Support comes in. We aim to
              revolutionize this entire process with the help of our more than a
              decade long experience in the industry. That's why we came up with
              our four step process, a mantra that our experts follow to ensure
              a satisfying experience.
            </p>
            <div className="bn632-hover bn26 AboutBtn">About us</div>
          </div>
        </div>

        {/* End Why us */}

        {/* Begin Feedback */}
        <div className="Feedback">
          <h1 className="text-2xl font-bold text-center mb-6">
            Customer Feedback
          </h1>
          <p className="text-center feedbackp">
            We are proud to have a large number of satisfied customers who have
            time and again praised our services. We move forward with only one
            goal in our minds which is to provide the best.
          </p>
          <div className="FeedbackCardContainer">
            <div className="FeedbackCards">
              <h1>Oliver Smith</h1>
              <p>
                Extremely helpful and professional service. I would recommend
                this to all my friends.
              </p>
              <div className="ratings">
                <ReactStarsRating
                  isEdit={true}
                  value={4}
                  primaryColor={"#009BE8"}
                />
              </div>
            </div>
            <div className="FeedbackCards">
              <h1>Henry Clark</h1>
              <p>
                Hands down the best experience I have ever had. Their staffs are
                extremely polite and keep you guided throughout.
              </p>
              <div className="ratings">
                <ReactStarsRating
                  isEdit={true}
                  value={5}
                  primaryColor={"#009BE8"}
                />
              </div>
            </div>
            <div className="FeedbackCards">
              <h1>Thomas Collins</h1>
              <p>Really helpful, quick and easy utilities switching process.</p>
              <div className="ratings">
                <ReactStarsRating
                  isEdit={true}
                  value={4}
                  primaryColor={"#009BE8"}
                />
              </div>
            </div>
            <div className="FeedbackCards">
              <h1>Louie Carter</h1>
              <p>
                Direct Utilities Support has been so helpful, would definitely
                use again (this is the second time I've used them). Highly
                recommended!
              </p>
              <div className="ratings">
                <ReactStarsRating
                  isEdit={true}
                  value={5}
                  primaryColor={"#009BE8"}
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Feedback */}

        {/* Begin FAQ */}
        <div className="FAQ">
          <h1 className="text-center text-2xl font-bold mb-6">
            Frequently Asked Questions
          </h1>
          <p className="FAQp">
            At Direct Utilities Support, we understand our customers. Our more
            than a decade of experience assisting our customers with switching
            utilities has helped us learn the common queries that goes in a
            customer's mind when they are switching utilities.
          </p>
          <div className="mt-10">
            {faq.map((item, index) => {
              return (
                <Accordion
                  style={{
                    boxShadow: "none",
                    borderBottom: "1px solid #68707C",
                  }}
                  defaultExpanded
                  key={index}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="font-semibold"
                  >
                    <p>{item.question}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>{item.solution}</p>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
        {/* End Faq */}

        <div className="py-10 bg-white text-center">
          <h1 style={{ color: "#00B3E8" }} className="text-2xl font-bold">
            Call us at <a href="tel:08006345467">0208 471 7796</a>
          </h1>
          <p className="font-light text-lg">
            For any queries related to utilites switiching or renewal
          </p>
        </div>
      </div>

      {/* Moving Particles in the banner */}
      <Particles
        id="tsparticles"
        options={{
          fpsLimit: 60,
          particles: {
            color: {
              value: "#000",
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 0.2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 40,
            },
            shape: {
              type: "images",
              images: [
                {
                  src: ellipse,
                  height: 20,
                  width: 23,
                },
                {
                  src: plus,
                  height: 20,
                  width: 23,
                },
              ],
            },
            size: {
              random: true,
              value: 10,
            },
          },
          detectRetina: true,
        }}
      />
    </>
  );
};

export default Home;
