export const faqGas = [
  {
    question: "What affects the cost of business gas?",
    solution: `Business gas is calculated very differently to household gas. There are several factors that contribute to this, including: Your location, the size of your premises, your current supplier, current spend on business gas, current consumption of kWh units and current standing charges Meter type (standard, economy 7, maximum demand or half hourly)`,
  },
  {
    question: "What is an Meter Point Reference Number (MPRN) gas number?",
    solution:
      "The ‘Meter Point Reference Number’ or MPRN is the unique identification number provided to each Gas supply installed in the UK and can be found on your Gas bill or printed on the front of the meter itself.",
  },
  {
    question: "How do I convert Gas units to kWh?",
    solution:
      "In the UK, converting metered gas units to kWh is achieved by first using a correction factor of 1.02264 then multiplying by the calorific value. The calorific value is regulated but varies by supplier and Local distribution zone. It is usually between 37.5MJ/m3 and 43.0 MJ/m3 the gas meter is imperial and uses cubic feet, one unit equals 100 cubic feet. A metric conversion factor of 2.83 is used to convert to cubic meters. The result of either this imperial or metric meter equation is then divided by 3.6 to provide the total kWh.",
  },
  {
    question: "What is a Gas shipper?",
    solution:
      "In the UK a gas shipper is a company licensed by the Office of Gas and Electricity Markets (OFGEM) – to buy, sell and arrange transport of gas through the UK gas networks owned by gas transporters.",
  },
  {
    question: "When can I change gas supplier?",
    solution:
      "Most gas suppliers give you a window of between 6-12 months to secure a new contract. Securing a contract with a new supplier in due time, before your contract end date, will help prevent you from paying out of contract rates, also improving the ease of transition between one supplier to another. Securing a long-term, fixed rate tariff gas contract early could also help protect you from the elements, although it can’t be avoided forever, unfortunately. We recommend securing a new business gas deal early to avoid disappointment. Switching supplier can provide good savings for your business.",
  },
  {
    question: "Why would I use a broker/consultancy for my business gas?",
    solution:
      "Brokers and consultants take away the stress of having to compare quotes yourself, as well as securing contracts and resolving any issues away from your business. It gives you peace of mind when searching for gas deals that the paperwork and supplier communication will be predominantly completed for you.",
  },
  {
    question: "How do I find out when my gas contract ends?",
    solution:
      "You should be able to find your contract end date on the first page of your statement. However, some suppliers do not always publish this information. If you are having difficulty finding this, contact our gas experts who will be happy to help you gather this information.",
  },
];
