import React from "react";
import { FaHandPointDown } from "react-icons/fa";
import { BsChevronDoubleDown } from "react-icons/bs";
import { Link } from "react-router-dom";

/**
 * @description RoundedCards would display the cards.
 * @param title string,
 * @param icon string,
 * @param description string content to be displayed in the card,
 * @param link string to be used as a link,
 * @param color string to be used as a primary color,
 * @param textColor string to be used as a text color,
 * @returns JSX.Element
 */

const RoundedCards = (props) => {
  return (
    <div
      className={`flex flex-col items-center backdrop-filter transition-all duration-200 backdrop-opacity-10 hover:backdrop-blur-sm justify-between h-96 w-72 border-2 rounded-3xl p-2 group shadow-md shadow-[${props.color}] hover:shadow-2xl `}
      style={{
        backgroundColor: props.color,
        borderColor: props.color,
        color: props.textColor,
        mixBlendMode: "multiply",
      }}
    >
      <div className={`flex flex-col flex-1 justify-around items-center gap-4`}>
        <div className="rounded-full p-2 bg-gray-100 backdrop-filter backdrop-opacity-none h-16 w-16 flex justify-center duration-500 items-center group-hover:bg-white shadow-none shadow-white group-hover:shadow-lg">
          <img src={props.icon} alt={props.title + " icon"} />
        </div>
        <div className="flex flex-col justify-start items-center">
          <div className="font-bold text-2xl tracking-normal duration-500 group-hover:tracking-wider">
            {props.title}
          </div>
          <div
            className="w-0 group-hover:w-full h-[2px] duration-500 opacity-0 group-hover:opacity-100"
            style={{
              backgroundColor: props.textColor,
            }}
          ></div>
        </div>
        <div className="flex flex-1 text-ellipsis text-lg text-center">
          {props.description}
        </div>
      </div>
      <BsChevronDoubleDown
        size={30}
        color={props.textColor}
        className="bottom-2 absolute z-10 opacity-0 group-hover:opacity-100 group-hover:animate-bounce duration-200"
      />
      <div
        className={`-bottom-4 absolute h-8 p-2 px-4 bg-white rounded-full text-lg text-center flex flex-col justify-center items-center ring-1 group-hover:ring-2 ring-[${props.textColor}] duration-200 tracking-normal group-hover:tracking-wide`}
      >
        <Link to={props.link}>Learn More</Link>
      </div>
    </div>
  );
};

export default RoundedCards;
