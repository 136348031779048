import react from "react";
import { useState, useEffect } from "react";
import "../Home/Home.css";
import { BsTelephoneFill } from "react-icons/bs";
import Particles from "react-tsparticles";
import ellipse from "../../images/Ellipse.png";
import plus from "../../images/plus.png";
import bg from "../../images/companies/British-gas-2.png";
import cng from "../../images/companies/CNG.jpg";
import cgp from "../../images/companies/Crown-gas-power.jpg";
import eon from "../../images/companies/e.on-1.jpg";
import GazProm from "../../images/companies/Gazprom-1.jpg";
import Npower from "../../images/companies/Npower-1.jpg";
import opus from "../../images/companies/Opus-energy.jpg";
import scotish from "../../images/companies/scotishpower.jpg";
import SSE from "../../images/companies/Sse.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useWindowDimensions from "../../util/windows";
import bizElectricity from "../../images/businessElectricity.png";
import discovery from "../../images/right/discovery.png";
import plan from "../../images/right/plan.png";
import execute from "../../images/right/execute.png";
import delivery from "../../images/right/delivery.png";
import { faq } from "../Home/faq";
import { AiFillMail, AiFillPhone } from "react-icons/ai";
import { Accordion } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MdOutlineSavings } from "react-icons/md";
import { BiMoney } from "react-icons/bi";
import { IoMdBusiness } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { faqElectricity } from "./faqElectricity";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const BusinessElectricity = () => {
  const { height, width } = useWindowDimensions();
  const location = useLocation();
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: width > 1000 ? 9 : width / 100,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 0,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  function createData(size, unit) {
    return { size, unit };
  }

  const rows = [
    createData("Micro", "18.84p"),
    createData("Small", "18.89p"),
    createData("Medium", "17.72p"),
    createData("Large", "17.44p"),
    createData("Very Large", "17.22p"),
  ];
  return (
    <>
      <div className="overflow-x-hidden">
        {/* space to take space for navigation below */}
        <div className="space"></div>
        <div className="banner">
          <div className="bannerInfo">
            <div className="flex flex-row px-2 pt-2 rounded-xl highlighted">
              <span className="rounded-full redCircle">
                <img src={bizElectricity} alt="Electricity" />
              </span>
              <p className="self-center ml-3 text-gray-700 font-medium">
                Business Electricity
              </p>
            </div>
            <div className="font-bold bigText">
              Compare
              <br />
              Electricity
              <br />
              With Experts
            </div>
            <div className="smallText text-gray-700">
              We compare electricity prices among UK
              <br />
              top utility providers to offer you electricity
              <br />
              at unbelievably cheap prices.
            </div>

            <div className="contact">
              <a href="tel:0208 471 7796">
                <button class="bn632-hover bn26 flex flex-row justify-center items-center">
                  <BsTelephoneFill /> &nbsp; 0208 471 7796
                </button>
              </a>
              {/* <p className="self-center">
                <BsTelephoneFill />
              </p>
              <p>0208 471 7796</p> */}
            </div>
          </div>
          <div className="bannerImage">
            <div className="businessElectricity"></div>
          </div>
        </div>
        {/* End of the Banner */}

        {/* the blue band below */}
        {/* <div className="band"></div> */}

        {/* Begin ---- Utility Companies train */}

        <div className="utilityCompanies shadow-2xl">
          <p className="mb-2 text-gray-700 mx-5">
            We compare prices among the most trusted utility suppliers in UK.
          </p>
          <Slider {...settings} className="UtilityGallery">
            <img src={bg} alt="British Gas" />
            <img src={cng} alt="CNG" />
            <img src={cgp} alt="Crown Gas Power" />
            <img src={eon} alt="Eon" />
            <img src={GazProm} alt="GazProm" />
            <img src={Npower} alt="Npower" />
            <img src={opus} alt="Opus" />
            <img src={scotish} alt="Scotish" />
            <img src={SSE} alt="SSE" />
            <img src={bg} alt="British Gas" />
            <img src={cng} alt="CNG" />
            <img src={cgp} alt="Crown Gas Power" />
            <img src={eon} alt="Eon" />
            <img src={GazProm} alt="GazProm" />
            <img src={Npower} alt="Npower" />
            <img src={opus} alt="Opus" />
            <img src={scotish} alt="Scotish" />
            <img src={SSE} alt="SSE" />
          </Slider>
        </div>

        {/* End ---- Utility Companies train */}

        <div className="Switching">
          <h1 className="text-white text-2xl font-light self-center text-center pt-10 pb-8">
            How can Direct Utilities Support help you
          </h1>
          <p className="ServicesTitle"></p>
          <div className="flex flex-row justify-evenly gap-x-10 gap-y-20 mt-12 mb-12 flex-wrap">
            <div className="flex flex-col items-center">
              <p
                style={{ fontSize: "3em" }}
                className="text-xl font-light flex flex-row pb-4"
              >
                <IoMdBusiness /> <span className="pl-4 mt-2">10K</span>
              </p>
              <p className="text-xl">Businesses Helped</p>
            </div>
            <div className="flex flex-col items-center">
              <p
                style={{ fontSize: "3em" }}
                className="text-xl font-light flex flex-row pb-4"
              >
                <MdOutlineSavings /> <span className="pl-4 mt-2">45%</span>
              </p>
              <p className="text-xl">Cost Savings On Bills</p>
            </div>
            <div className="flex flex-col items-center">
              <p
                style={{ fontSize: "3em" }}
                className="text-xl font-light flex flex-row pb-4"
              >
                <BiMoney /> <span className="pl-4 mt-2">£11m</span>
              </p>
              <p className="text-xl">Annualy Customer Spend</p>
            </div>
          </div>
        </div>

        <div className="bg-white py-10 px-10">
          <div className="flex flex-row flex-wrap lg:flex-nowrap gap-10 justify-center">
            <div className="pt-5">
              <h1 className="text-2xl pb-5">
                Save more on your <b>Business Electricity Bills</b> with us
              </h1>
              <p>
                {" "}
                <b>
                  We compare electricity prices and specialise in negotiating
                  bespoke rates for businesses large and small. It’s all down to
                  our excellent understanding of our customer’s needs and our
                  outstanding relationships with our suppliers.
                </b>
                <br />
                <br />
                WWe understand that you’re busy and that electricity procurement
                may not be your highest priority. You probably compare
                electricity prices once every 2-3 years and find yourself
                putting off doing it because of how complex and time-consuming
                it can be.
                <br /> <br /> Here at Direct Utilities Support, we have a large
                team of energy experts that compare electricity prices from over
                various suppliers and make it their job to negotiate competitive
                prices for our customers every day. We’re ‘outstanding’ at it
                and that’s why over 10,000 businesses have used us to negotiate
                their contracts for them year after year.
              </p>
            </div>
            <div
              style={{ backgroundColor: "#00B3E8" }}
              className="py-10 px-5 text-white self-center w-full rounded-lg"
            >
              <h1 className="text-2xl font-bold pb-2">
                Energy Switching Made Easy
              </h1>
              <div className="flex flex-row py-2">
                <TiTick size={20} />{" "}
                <p className="-mt-1 text-xl font-semibold">
                  Discovering Customer requirements
                </p>
              </div>
              <div className="flex flex-row py-2">
                <TiTick size={20} />{" "}
                <p className="-mt-1 text-xl font-semibold">
                  Plan best suitable Business Electricity provider
                </p>
              </div>
              <div className="flex flex-row py-2">
                <TiTick size={20} />{" "}
                <p className="-mt-1 text-xl font-semibold">
                  Execute required paper work and hassle of switching
                </p>
              </div>
              <div className="flex flex-row py-2">
                <TiTick size={20} />{" "}
                <p className="-mt-1 text-xl font-semibold">
                  Deliver a satisafactory service
                </p>
              </div>
            </div>
          </div>
        </div>

        <div style={{ backgroundColor: "#01114C" }} className="py-10 px-10">
          <h1 className="text-center text-2xl font-bold text-white pb-10">
            Our Process to Happy Customers
          </h1>
          <div className="flex flex-row flex-wrap justify-evenly gap-x-10 gap-y-10">
            <div className="flex flex-col w-56">
              <div className="w-48 h-48">
                <img src={discovery} alt="Discovering Business" />
              </div>
              <div className="text-white text-sm">
                <p>
                  Discovering about the business is our most important stage as
                  it helps us understand your business and the industry it
                  operates in, which enables us to assess better services for
                  you.
                </p>
              </div>
            </div>
            <div className="flex flex-col w-56">
              <div className="w-48 h-48">
                <img src={plan} alt="Planning Business" />
              </div>
              <div className="text-white text-sm">
                <p>
                  Planning is where our years of experience comes in. After we
                  have learned about your business we compare through different
                  providers and find the best one for you.
                </p>
              </div>
            </div>
            <div className="flex flex-col w-56">
              <div className="w-48 h-48">
                <img src={execute} alt="Executing Business" />
              </div>
              <div className="text-white text-sm">
                <p>
                  Execution is the stage where the hassle of paperwork comes in
                  but our experts have got you covered. We take care of all the
                  paperwork and the switching process and keep you guided
                  thourghout the process.
                </p>
              </div>
            </div>
            <div className="flex flex-col w-56">
              <div className="w-48 h-48">
                <img src={delivery} alt="delivering Business" />
              </div>
              <div className="text-white text-sm">
                <p>
                  After the switch is complete, our services is delivered to you
                  without you having to go thourhg the entire trouble of
                  switching. You can save more and eventually do more.
                </p>
              </div>
            </div>
          </div>
          <p className="text-white text-center text-sm px-10 mt-14 pt-5 border-t-2 border-white">
            Our services are absolutely free which means we don't take single
            penny from you. We earn from commissions from the suppliers.
          </p>
        </div>

        {/* Begin FAQ */}
        <div className="FAQ">
          <h1 className="text-left text-2xl font-bold mb-6 px-4">
            Business Electricity Frequently Asked Question
          </h1>
          <p className="text-left text-sm w-4/4 lg:w-3/4 px-4">
            At Direct Utilities Support, we understand our customers. Our more
            than a decade of experience assisting our customers with switching
            utilities has helped us learn the common queries that goes in a
            customer's mind when they are switching utilities.
          </p>
          <div className="mt-10">
            {faqElectricity.map((item, index) => {
              return (
                <Accordion
                  style={{
                    boxShadow: "none",
                    borderBottom: "1px solid #68707C",
                  }}
                  defaultExpanded
                  key={index}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="font-semibold"
                  >
                    <p>{item.question}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>{item.solution}</p>
                  </AccordionDetails>
                </Accordion>
              );
            })}
            <Accordion
              style={{
                boxShadow: "none",
                borderBottom: "1px solid #68707C",
              }}
              defaultExpanded
              key={10}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="font-semibold"
              >
                <p>Business Electricity Prices by Size</p>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <p>
                        Whilst business electricity rates largely depend on
                        several factors, including usage and the location of the
                        premises, here are the average business electricity
                        prices per kWh by size in the UK:
                      </p>
                      <TableRow>
                        <TableCell>
                          <b>Size</b>
                        </TableCell>
                        <TableCell>
                          <b>Unit Price/kWh</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{row.size}</TableCell>
                          <TableCell>{row.unit}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <p className="italic text-sm">
                    Prices are for Q4 of 2021. Prices accurate as of April 2022
                    according to the UK Government.
                  </p>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        {/* End Faq */}

        <div
        style={{
          background:
            "linear-gradient( 41deg, rgba(2, 0, 36, 1) 0%, rgba(240, 245, 250, 1) 0%, rgba(235, 244, 252, 1) 100%)",
        }}
        className="py-10 px-10"
      >
        <h1 className="text-2xl self-center text-center font-bold">
          Services We Provide
        </h1>
        <div className="flex flex-row flex-wrap gap-x-10 gap-y-28 justify-evenly pt-10 mb-20">
          <div style={{ width: 300, height: 250 }} className="shadow-lg">
            <a href="/services/business-gas">
              <div className="rounded-t-lg bggas">
                <div
                  style={{ backgroundColor: "rgba(0,179,232,0.8)" }}
                  className="h-full rounded-t-lg"
                >
                  <h1 className="text-xl font-bold px-5 pt-5 pb-2 text-white">
                    Business Gas
                  </h1>
                  <p className="px-5 text-white font-lg">
                    Switching Utiilties can be a lot of hassle, but it doesn't
                    have to be that way. With Direct Utilities Support you can
                    forget the hassle and focus on your business.
                  </p>
                </div>
              </div>
              <div className="bg-white text-lg font-semibold text-center py-5 rounded-b-lg">
                <p>Business Gas</p>
              </div>
            </a>
          </div>

          <div style={{ width: 300, height: 250 }} className="shadow-lg">
            <a href="/services/business-electricity">
              <div className="rounded-t-lg bgelectricty">
                <div
                  style={{ backgroundColor: "rgba(0,179,232,0.8)" }}
                  className="h-full rounded-t-lg"
                >
                  <h1 className="text-xl font-bold px-5 pt-5 pb-2 text-white">
                    Business Electricity
                  </h1>
                  <p className="px-5 text-white font-lg">
                    Direct utilites Support has helped business save millions
                    over the years. We can help you switch to the best
                    electricity provider for your business.
                  </p>
                </div>
              </div>
              <div className="bg-white text-lg font-semibold text-center py-5 rounded-b-lg">
                <p>Business Electricity</p>
              </div>
            </a>
          </div>

          <div style={{ width: 300, height: 250 }} className="shadow-lg">
            <a href="/services/business-telecom">
              <div className="rounded-t-lg bgtelecom">
                <div
                  style={{ backgroundColor: "rgba(0,179,232,0.8)" }}
                  className="h-full rounded-t-lg"
                >
                  <h1 className="text-xl font-bold px-5 pt-5 pb-2 text-white">
                    Business Telecom
                  </h1>
                  <p className="px-5 text-white font-lg">
                    Help you business stay connected with the world. From mobile
                    phone and sim cards to landlines and VOIP, Direct Utilities
                    Support can guide you through the process.
                  </p>
                </div>
              </div>
              <div className="bg-white text-lg font-semibold text-center py-5 rounded-b-lg">
                <p>Business Telecom</p>
              </div>
            </a>
          </div>

          <div style={{ width: 300, height: 250 }} className="shadow-lg">
            <a href="/services/business-water">
              <div className="rounded-t-lg bgwater">
                <div
                  style={{ backgroundColor: "rgba(0,179,232,0.8)" }}
                  className="h-full rounded-t-lg"
                >
                  <h1 className="text-xl font-bold px-5 pt-5 pb-2 text-white">
                    Business Water
                  </h1>
                  <p className="px-5 text-white font-lg">
                    Over the years, Direct Utilities Support has helped
                    businesses do better by switching to the best water
                    provider. We take care of all the paperwork and hassle of
                    switching.
                  </p>
                </div>
              </div>
              <div className="bg-white text-lg font-semibold text-center py-5 rounded-b-lg">
                <p>Business Water</p>
              </div>
            </a>
          </div>
        </div>
      </div>

        <div className="py-10 bg-white text-center">
          <h1 style={{ color: "#00B3E8" }} className="text-2xl font-bold">
            Call us at <a href="tel:08006345467">0208 471 7796</a>
          </h1>
          <p className="font-light text-lg">
            For any queries related to utilites switiching or renewal
          </p>
        </div>
      </div>
      {/* Moving Particles in the banner */}
      <Particles
        id="tsparticles"
        options={{
          fpsLimit: 60,
          particles: {
            color: {
              value: "#000",
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 0.2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 40,
            },
            shape: {
              type: "images",
              images: [
                {
                  src: ellipse,
                  height: 20,
                  width: 23,
                },
                {
                  src: plus,
                  height: 20,
                  width: 23,
                },
              ],
            },
            size: {
              random: true,
              value: 10,
            },
          },
          detectRetina: true,
        }}
      />
    </>
  );
};

export default BusinessElectricity;
