import React, { useEffect } from "react";
import "../pages/Home/Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../util/windows";
import "./Policy.css";

const Complaints = () => {
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="overflow-x-hidden">
      <div className="h-12 md:h-20"></div>

      <div className="px-10 pt-12 pb-10 bg-gradient-to-r from-cyan-500 to-blue-500">
        <p className="text-3xl md:text-4xl pb-3 text-white font-bold">
          Direct Utilities Support
        </p>
        <p className="text-xl text-white font-light">Complaints Procedure</p>
      </div>

      <div className="px-10 py-10">
        <h1 className="text-3xl">
          <>Our Promise</>
        </h1>
        <p>
          We endeavour to offer the highest level of service throughout the
          duration of your contract However, if something does go wrong and you
          want to make a complaint, we will investigate any issues and aim to
          rectify any issues promptly, where possible.
        </p>
        <p>
          We embrace any feedback and continually look to improve. However, if a
          customer feels dissatisfied with any element of our service, we
          welcome the opportunity to put things right.
        </p>
        <p>
          We would like to think that it would not be necessary to go beyond the
          first step, however we understand that in very rare circumstances you
          may need to present your case where the end result leaves you
          unsatisfied.
        </p>
        <h4 className="mb-0">Step 1</h4>
        <p>
          A complaint is any expression of dissatisfaction with the service
          received by Direct Utilities Support. A complaint may be made in writing, email,
          by telephone or in any other form. Please provide as much detail as
          possible about the reasons for your complaint and the outcome you
          would like to see.<br /><br />
          By email at –admin@directutilitiessupport.co.uk<br />
          By phone – 02084717796<br />
          By post –Suite 7 Station Chambers , High Street North ,London E6 1JE<br />

        </p>
        <h4 className="mb-0">Step 2</h4>
        <p>
          We will send you a written or electronic acknowledgement of a
          complaint within five business days of receipt, identifying the person
          who will be handling the complaint for the business. Wherever
          possible, that person will not have been directly involved in the
          matter, which is the subject of the complaint, and will have authority
          to settle the complaint.
        </p>
        <h4 className="mb-0">Step 3</h4>
        <p>
          Within two weeks of receiving a complaint we will send you either:
        </p>
        <p>1. a final response which adequately addresses the complaint; or</p>
        <p>
          2. a holding response, which explains why we are not yet in a position
          to resolve the complaint and indicates when we will make further
          contact with you.
        </p>

        <p>
          3. If we do not hear from you within a timescale of 7 days after
          issuing our final response, we will assume you are satisfied with the
          outcome.
        </p>

        <h4 className="mb-0">Step 4</h4>
        <p>
          If you are not satisfied with our final response, we will be happy to
          consider a further response, but in the absence of new evidence or
          material flaws being evident in our findings, it is unlikely to change
          the outcome of our investigation.
        </p>
        <h4 className="mb-0">Step 5</h4>
        <p>
          We will send you a final response which adequately addresses the
          complaint no later than eight weeks from receiving a complaint
        </p>
        <h4 className="mb-0">Step 6</h4>
        <p>
          If you are unhappy with our final response, you may be able to seek help from Ombudsman
          Services . Ombudsman Services is impartial, free to use and can be used if a complaint is
          over 8 weeks old or reaches deadlock.  Their contact details :
          {" "}
          <br /><br />
          Post: Ombudsman Services: Energy, P.O. Box 966, Warrington, WA4 9DF<br />
          Phone: 0330 440 1624<br />
          Email: enquiry@ombudsman-services.org<br />
          We are not responsible for the content on their website.

        </p>
      </div>

      <div className="py-10 bg-white text-center">
        <h1 style={{ color: "#00B3E8" }} className="text-2xl font-bold">
          Call us at <a href="tel:08006345467">0208 471 7796</a>
        </h1>
        <p className="font-light text-lg">
          For any queries related to utilites switiching or renewal
        </p>
      </div>
    </div>
  );
};

export default Complaints;
