import React, { useEffect } from "react";
import "../pages/Home/Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../util/windows";
import "./Policy.css";

const Tc = () => {
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="overflow-x-hidden">
      <div className="h-12 md:h-20"></div>

      <div className="px-10 pt-12 pb-10 bg-gradient-to-r from-cyan-500 to-blue-500">
        <p className="text-3xl md:text-4xl pb-3 text-white font-bold">
          Direct Utilities Support
        </p>
        <p className="text-xl text-white font-light">Terms and Conditions</p>
      </div>

      <div className="px-10 py-10">
        <div>
          <h4>
            <b>Terms and Conditions </b>
          </h4>
          <p>
            The following document sets out the terms and conditions of service
            for Direct Utilities Support Limited. We have set out below the principal
            terms which come into effect once a customer uses the Direct Utilities Support
            switching service.
          </p>
          <p>
            If you have any further questions about these terms and conditions,
            or anything else, please contact our Customer Services team who will
            be more than happy to help you further. Our Customer Services team
            can be contacted on 0800 007 4001
          </p>
          <p>
            These terms and conditions govern the use of the services Direct Utilities Support provides to customers. It is important that you read these
            terms and conditions carefully before accepting these terms and
            conditions. By using the Direct Utilities Support service/s you signify that
            you have read, understood, and agree to be bound by these terms and
            conditions. If you do not agree to these terms – DO NOT use our
            service.
          </p>
          <h4>
            <b>Price Comparison / Switching Service</b>
          </h4>
          <p>
            Direct Utilities Support provides a price comparison / switching service to UK
            businesses. To be eligible to use this service you must be;
          </p>
          <ol>
            <li>A business that is resident in the UK;</li>
            <li>Eighteen years or older (if a sole trader);</li>
            <li>
              Meet the credit scoring requirements of any supplier (if
              applicable)
            </li>
          </ol>
          <p>
            We reserve the right to refuse to provide our services to any
            customer at our discretion.
          </p>
          <p>
            If the customer agrees to use our services, Direct Utilities Support will use
            its reasonable endeavours to identify, negotiate and secure
            competitive energy prices. Energy prices are obtained from a panel
            of energy suppliers using price books provided by suppliers.
          </p>
          <p>
            The customer agrees to provide Direct Utilities Support with accurate
            information to allow accurate quotations to be obtained – including
            estimated or actual usage of energy, contract end dates and other
            know factors that may influence prices and quotations.
          </p>
          <p>
            The provision of a quotation by a supplier via Direct Utilities Support does
            not constitute an offer to the customer and offers will each have
            their own supplier’s terms and obligations attached.
          </p>
          <p>
            If the customer accepts a quotation and a contract is executed
            between the customer and the supplier, the contract is binding, and
            the customer will not be permitted to revoke such an acceptance.
          </p>
          <p>
            The supplier shall be entitled at any time to refuse to accept a
            customer’s offer of acceptance at their discretion.
          </p>
          <p>
            If the customer and supplier wish to enter into a contract, Direct Utilities
            Support will provide a switching service (or renewal service) which
            will include organising the contract between the customer and the
            energy supplier for the purchase and supply of the energy services.
          </p>
          <p>
            Direct Utilities Support will not be responsible for any delays or failures
            caused by either the customer, Direct Utilities Support, the proposed supplier
            or any existing supplier in relation to effecting either a transfer
            of supply or renewal of supply.
          </p>
          <p>
            Direct Utilities Support makes no guarantees as to either the quality of the
            service suppliers operates, or the prices obtained at quotation.
          </p>
          <p>
            Energy prices fluctuate on a daily basis and whilst we attempt to
            help secure competitive rates to enable switching or renewal, we
            cannot guarantee any quote is the cheapest.
          </p>
          <p>
            The customer is reminded that ultimately it is their responsibility
            to validate the accuracy of any quotations presented. Also, it is
            the customers responsibility to ensure that the contract(s) have
            been submitted to the supplier and will go live. This is usually
            represented by receiving/accepting communication from either the new
            or old supplier to confirm that the switch/ renewal is occurring.
          </p>
          <p>
            The customer acknowledges that they are entering into a contract
            with a supplier for the provision of energy supply and not with
            Direct Utilities Support. The customer agrees that Direct Utilities Support is not
            liable in any way for any dealings, contracts or transactions
            between the customer and the supplier and that any such
            transactions, contracts, dealings or payment obligations are the
            customers responsibility and risk.
          </p>
          <p>
            The customer agrees by using the service that Direct Utilities Support is an
            independent energy brokerage and that Direct Utilities Support is not
            instructed by the customer to act as their agent or representative.
          </p>
          <p>
            The customer agrees that Direct Utilities Support are not responsible for the
            provision of any supplier services.
          </p>
          <h4>
            <b>Charges </b>
          </h4>
          <p>
            Direct Utilities Support is remunerated directly by the suppliers and receives
            a commission for securing and finalising the contract between the
            customer and the energy supplier. This is usually by way of an
            uplift that is applied within your unit cost for energy.
          </p>
          <p>
            The cost of Direct Utilities Support’s service is therefore included within
            the cost of the energy contract that you agree with the supplier.
          </p>
          <p>
            By way of example we may secure a price for electricity at 16 pence
            per KWH and apply a 0.9p uplift. The contracted price we would offer
            would therefore be 16.9p. Our commission would be calculated as 0.9p
            multiplied by your annual energy consumption and multiplied by the
            contract term.
          </p>
          <p>
            Our uplifts depend on the size of contract, length and other factors
            such as credit risks. Our average uplift is a few percent of your
            total energy cost and typically under 1p.
          </p>
          <p>
            Because we get paid by suppliers, we only offer prices from those
            suppliers on our panel. Our panel currently consists of over 90% of
            the UK business energy suppliers (by customer numbers) so we have a
            very wide range to price from.
          </p>
          <p>
            Further information on how much commission we expect to receive on a
            contract can be found by contacting our customer services team.
          </p>
          <h4>
            <b>Duplicate contract fees</b>
          </h4>
          <p>
            Where the customer enters into a contract with a supplier and uses
            the Direct Utilities Support service, this contract must be accepted as
            legally binding. In the event the customer enters into another
            contract with another supplier, and the contract facilitated by
            Direct Utilities Support fails to proceed to live status, Direct Utilities Support will
            receive no fee for its services from a supplier and will have
            incurred time and loss
          </p>
          <p>
            In those circumstances, Direct Utilities Support reserves the right to charge
            the customer a one off “failed contract” fee of £750 per meter, or
            the total value of commission we would have earnt on the contract
            introduction (per meter) – whichever is the higher figure. This is
            in addition to any charges the supplier may also attempt to recover
            for the failed contract. It is important that once you have entered
            into a contract with a supplier that you honour that contract and do
            not enter into duplicate contracts with other suppliers.
          </p>
          <p>
            A “Win Back” is where we introduce you back to your incumbent
            supplier (renew) after beating another price from another supplier
            you have agreed to move too. By winning back the contract you are
            effectively cancelling the contract you have agreed to leave your
            incumbent supplier and renew again with them.
          </p>
          <h4>
            <b>Change of Tenancy Notifications and Fees</b>
          </h4>
          <p>
            Where a customer enters into an energy contract through Direct Utilities Support but decides to move premises mid contract the contact will
            terminate. This is called a “change of tenancy” or “COT”. Direct Utilities Supports fees are adjusted by supplier if a COT occurs. Its therefore
            important that you notify Direct Utilities Support at least 14 days before the
            end of your energy contract in the event that you are moving
            premises and a COT will occur.
          </p>
          <p>
            Failure to notify Direct Utilities Support will incur a fee for the loss /
            reduction in commission the supplier pays us. Where a customer has
            completed more than 50% of their contract term (to the date of the
            COT), a failure to notify fee of £500 will apply. Where less than
            50% of their contract term has been completed (to the date of the
            COT), a failure to notify fee of £750 will apply. In both instances
            Direct Utilities Support reserves the right to waive such fees in exceptional
            circumstances.
          </p>
          <h4>
            <b>Failure to go live</b>
            <br />
            If a contract fails to go live (commence supply) due to the customer
            failing to provide us with information or documentation requested by
            either us or the supplier, or through a failure of a supplier /
            supplier refusing to accept supply, Direct Utilities Support reserves the
            right to charge the customer a one off fee of £750. Critical
            information required, but not limited too includes – signed letters
            of authority, current supplier information, meter numbers, contract
            start or end dates, termination notifications, supplier ceasing to
            trade / onboard contracts and banking details including setting up
            direct debits or standing orders with a new supplier.
          </h4>
          <h4>
            <b>Failure of a supplier</b>
          </h4>
          <p>
            If your current supplier fails, and you enter a supplier of last
            resort (SOLR), Direct Utilities Supports fees may not be paid by the supplier
            that fails. In those circumstances we will charge a fee of £750 (if
            our fees are not already paid by more than 80%) to cover our lost
            fees incurred. We may at our discretion agree to waive those fees if
            the customer instructs Direct Utilities Support to introduce another supplier
            to take over their SOLR contract.
          </p>
          <h4>
            <b>Auto Renew</b>
            <br />
            If you have provided us with delegated authority (DA) to renew your
            energy contract on your behalf, our Auto Renewal terms and
            conditions also apply and are binding.
          </h4>
          <p>&nbsp;</p>
        </div>
      </div>

      <div className="py-10 bg-white text-center">
        <h1 style={{ color: "#00B3E8" }} className="text-2xl font-bold">
          Call us at <a href="tel:08006345467">0208 471 7796</a>
        </h1>
        <p className="font-light text-lg">
          For any queries related to utilites switiching or renewal
        </p>
      </div>
    </div>
  );
};

export default Tc;
