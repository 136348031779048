import emailjs from "emailjs-com";
import React, { useEffect, useState } from "react";
import { BsTelephone } from "react-icons/bs";
import { HiOutlineMail, HiOutlineOfficeBuilding } from "react-icons/hi";
import { Formik, Field, Form, useField } from "formik";
import { AiFillPhone, AiFillMail } from "react-icons/ai";
import { useLocation } from "react-router-dom";

const Contact = () => {
  //emailjs
  const [MailSent, setMailSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_n886dtz",
        "template_0a06ydz",
        e.target,
        "N3_M_25XP-L2kPNAw"
      )
      .then((res) => {
        if (res.text === "OK") {
          setMailSent(true);
        }
      })
      .catch((err) => console.log(err));
  };
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const MyTextArea = ({ ...props }) => {
    const [field, meta] = useField(props);
    return (
      <>
        <textarea
          className="outline-none px-10 py-8 mb-10 rounded-md"
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </>
    );
  };

  return (
    <div className="overflow-x-hidden">
      <div className="h-12 md:h-20"></div>
      <div className="flex flex-col items-center bg-gradient-to-r from-cyan-500 to-blue-500 px-5 py-20">
        <div className="w-full md:w-3/4">
          {" "}
          <div>
            {" "}
            <h1 className="text-white font-bold text-4xl pb-5">Contact Us</h1>
            <p className="text-white text-xl md:w-3/4 lg:w-2/4 font-medium pb-10">
              Feel free to get in touch with us for any query related to
              switiching utilities or renewing your current supplier. We will be
              happy to talk.
            </p>
          </div>
          <Formik
            initialValues={{ name: "", email: "" }}
            onSubmit={async (values) => {
              await new Promise((resolve) => setTimeout(resolve, 500));
              alert(JSON.stringify(values, null, 2));
            }}
          >
            <Form
              onSubmit={sendEmail}
              className="flex flex-col justify-center w-full"
            >
              <Field
                className="h-10 border-b-2 border px-10 py-8 mb-5 outline-none rounded-md"
                name="name"
                type="text"
                placeholder="Enter your name"
                required
              />
              <Field
                className="h-10 border-b-2 border px-10 py-8 mb-5 outline-none rounded-md"
                name="phone"
                type="number"
                placeholder="Write your phone number"
              />
              <Field
                className="h-10 border-b-2 border px-10 py-8 mb-5 outline-none rounded-md"
                name="email"
                type="email"
                placeholder="Add your email, we never spam."
                required
              />
              <Field
                className="h-10 border-b-2 border px-10 py-8 mb-5 outline-none rounded-md"
                name="subject"
                type="text"
                placeholder="Add a subject"
                required
              />
              <MyTextArea
                name="query"
                rows="6"
                placeholder="Write your queries here..."
                required
              />
              <button
                style={{ backgroundColor: "#01114C" }}
                className="shadow-xl rounded-md h-10 w-20 text-white font-semibold"
                type="submit"
              >
                Submit
              </button>
              {MailSent && (
                <p className="pt-3 text-white italic font-semibold">
                  Thank you for contacting us!
                </p>
              )}
            </Form>
          </Formik>
        </div>
      </div>

      <div className="flex flex-row flex-wrap gap-y-20 gap-x-10 justify-start md:justify-evenly py-28 px-10 sm:px-10 bg-white">
        <div className="flex flex-row w-64">
          <div className="pt-2 pr-3">
            <BsTelephone size={30} color={"#071750"} />
          </div>
          <p className="font-medium">
            <span className="block">
              <b>Phone</b>
            </span>
            0208 471 7796 (lines open between 9am-5:30pm Monday to Thursday and
            9am-5pm on Fridays)
          </p>
        </div>

        <div className="flex flex-row w-64">
          <div className="pr-3">
            <HiOutlineMail size={30} color={"#071750"} />
          </div>
          <div className="font-medium">
            <span className="block">
              <b>Email</b>
            </span>
            <a
              className="text-blue-600 cursor-pointer"
              href="mailto:info@directutilitiessupport.co.uk"
            >
              info@directutilitiessupport.co.uk
            </a>
          </div>
        </div>

        <div className="flex flex-row w-64">
          <div className="pr-3">
            <HiOutlineOfficeBuilding size={30} color={"#071750"} />
          </div>
          <div className="font-medium">
            <span className="block">
              <b>Address</b>
            </span>
            <p>
              126-128 Uxbridge Rd, London, W13 8QS <br />
              (1st Floor)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
